import * as React from "react"
import * as styles from "./result.module.scss"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Button from "../../components/button"
import Footer from "../../components/v2/footer"
import Header from "../../components/v2/header"
import SEO from "../../components/seo"
import Section from "../../components/section"
import { graphql } from "gatsby"

const InfluencerResultPage = ({ data }) => {
  return (
    <>
      <SEO
        title="Magnetic Marketing Archetype Quiz | Influencer"
        description="Discover your magnetic marketing archetype and learn how to leverage it to grow your business."
        noIndex={ true }
      />
      <Header btnThem="primary" />
      <main className={ styles.result }>
        <Section theme="dark" style={ { marginTop: `4.5rem`, overflow: `hidden` } }>
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <h1 className="color-white"><span className="heading-xs-upper">Your Magnetic Marketing Archetype is an...</span>Influencer!</h1>
              <p className="heading-sm color-white">You were influential before Instagram was a thing. In a sea of people who aimlessly follow the latest trends, you’re the one who’s setting them. You are a natural-born trailblazer who prefers to take the road less traveled. (Robert Frost would be so proud.)</p>
              <p className="heading-sm color-white">Your personality is magnetic and infectious, constantly drawing others in. Your audience loves listening to what you have to say and can’t wait to hear the latest updates from you. You don’t just capture people’s attention; you know how to keep it!</p>
              <p className="heading-sm color-white">Sometimes you are so forward-thinking that it may seem like no one else “gets it,” but don’t worry, Influencer! That just means you’re leaning into your marketing strengths as an innovator and dreamer.</p>
              <p className="heading-sm color-white">Our advice? Don’t let your influential power keep you from personally connecting with individuals inside your ever-evolving audience. They often trust your recommendations and advice implicitly, so keep this in mind when you’re building your online empire!</p>
            </div>
            <div className="col-lg-5">
              <div className={ styles.quote }>
                <p className="heading-sm color-white">“One of the best ways to influence people is to make them feel important.”<br/><br/><span style={ { color: `#dfe3ff` } }>- Roy T. Bennett</span></p>
                <svg width="344" height="345" viewBox="0 0 344 345" fill="none" xmlns="http://www.w3.org/2000/svg" style={ { position: `absolute`, right: `-150px`, top: `-200px`, zIndex: `0` } }>
                  <ellipse cx="172" cy="172.121" rx="172" ry="172.121" fill="#23194D"></ellipse>
                </svg>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <h3 className="heading-lg color-black text-center">Ready to get personal?</h3>
              <p className="heading-sm color-pink text-center"><strong>Read on for 3 exercises to better understand your archetype!</strong></p>
              <p className="heading-sm color-blue text-center">As an Influencer, you may struggle with turning your influence into income at times. We want to help you make more sales without the hassle and hustle of traditional marketing. Your strategies should be as unique as you are! Here’s how to get started...</p>
              <div className={ styles.list }>
                <div className={ styles.item }>
                  <div className={ styles.heading }>
                    <div className={ styles.num }><strong className="heading-sm color-pink text-center">1</strong></div>
                    <p className="heading-sm color-black"><strong>Clarify your core values.</strong></p>
                  </div>
                  <div className={ styles.text }>
                    <p className="text-body color-black">What do you believe in? How do those beliefs drive your business? Growth can’t happen if you’re out of alignment with your values. When you have the right values, you’ll find it easier to connect with your target audience. (Psst... read <a href="https://www.tryinteract.com/blog/creating-customer-personas-for-a-personality-quiz/" target="_blank" rel="noreferrer">How to Create Customer Personas</a> for more tips)</p>
                  </div>
                </div>
                <div className={ styles.item }>
                  <div className={ styles.heading }>
                    <div className={ styles.num }><strong className="heading-sm color-pink text-center">2</strong></div>
                    <p className="heading-sm color-black"><strong>Check out the competition.</strong></p>
                  </div>
                  <div className={ styles.text }>
                    <p className="text-body color-black">You want to make sure your voice is unique. It’s too easy for businesses in the same niche to blend together — be the one to stand out with your content! (Psst... read <a href="https://www.tryinteract.com/blog/use-your-expertise-to-create-the-perfect-quiz-for-your-audience/" target="_blank" rel="noreferrer">How to Utilize Your Expertise</a> for more tips)</p>
                  </div>
                </div>
                <div className={ styles.item }>
                  <div className={ styles.heading }>
                    <div className={ styles.num }><strong className="heading-sm color-pink text-center">3</strong></div>
                    <p className="heading-sm color-black"><strong>Strengthen your pitch.</strong></p>
                  </div>
                  <div className={ styles.text }>
                    <p className="text-body color-black">Are you actively promoting a product or service you believe in, whether it’s your own or from a company you love? Make sure you nurture your audience through educational content first. Then you can lead with your simplified elevator pitch. (Psst... read <a href="https://www.tryinteract.com/blog/quiz-based-growth-strategies/" target="_blank" rel="noreferrer">How to Sell Anything with Growth Strategies</a> for more tips)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={ { position: `relative`, zIndex: `99` } }>
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-12">
                <h3 className="heading-lg color-black text-center" style={ { marginTop: `8rem` } }>Hold up — let us introduce ourselves...</h3>
              </div>
            </div>
            <div className="row no-gutters justify-content-center align-items-center">
              <div className="col-lg-6">
                <GatsbyImage image={ getImage(data.aboutImage) } alt="Interact team group picture"/>
              </div>
              <div className="col-lg-6">
                <p className="heading-sm color-black" style={ { background: `white`, padding: `3rem 4rem` } }>We’re the small but mighty team behind Interact, the insanely intuitive quiz builder your business friends already love. We help entrepreneurs and creators just like you make personality-infused quizzes to grow their businesses and reach. Want in on the action? Check your email inbox for more info!</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center" style={ { position: `relative`, zIndex: `9` } }>
            <div className="col-lg-12">
              <h3 className="heading-lg color-black text-center" style={ { marginTop: `8rem` } }>Not sure where to start?</h3>
              <p className="heading-sm color-muted-foreground text-center">These resources will show you how to get started with quiz marketing!</p>
            </div>
            <div className="col-lg-8" style={{ marginTop: '3rem' }}>
              <div className={ styles.box }>
                <p className="heading-md color-black"><strong>Check out our Entrepreneur’s Corner!</strong></p>
                <p className="text-body color-muted-foreground text-center">It’s an archive full of Entrepreneurs’ stories just like yours! We are diving deep into overcoming obstacles when growing a business and showing other new Entrepreneurs that you aren’t alone.</p>
                <Button theme="secondary" type="external" href="https://www.tryinteract.com/blog/">Visit Entrepreneur's Corner</Button>
              </div>
              <div className={ styles.box }>
                <p className="heading-md color-black"><strong>Join our free Facebook group!</strong></p>
                <p className="heading-sm color-muted-foreground text-center">You can connect with other Influencers like you, and meet some of the other types as you learn about quizzes, email marketing, and growing your business like whoa. Who knows? You may just find your newest business bestie in there!</p>
                <Button theme="secondary" type="external" href="https://www.facebook.com/groups/interactquizcommunity" style={ { margin: `1rem 0` } }>Join Facebook Group</Button>
              </div>
              <div className={ styles.box }>
                <p className="heading-md color-black"><strong>Follow us on Instagram!</strong></p>
                <p className="heading-sm color-muted-foreground text-center">Folow us to see what we’re up to and get the best tips from successful quiz creators in our community. We’ll see you there!</p>
                <Button theme="secondary" type="external" href="https://www.instagram.com/tryinteract/" style={ { margin: `1rem 0` } }>Follow us on Instagram</Button>
              </div>
            </div>
          </div>
        </Section>
      </main>
      <Footer/>
    </>
  )
}

export default InfluencerResultPage

export const query = graphql`
  query {
    aboutImage: file(relativePath: { eq: "about/group.jpg" }) {
      childImageSharp {
        gatsbyImageData (
          width: 558,
          quality: 100,
        )
      }
    }
  }
`